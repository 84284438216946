import { SaveBar } from "@shopify/app-bridge-react";
import useMode from "@/hook/useMode";
const SaveBarComponent = props => {
  console.log("SaveBarComponent ",props);
  const { children = <></> } = props || {};
  const { csMode } = useMode();
  if (csMode) {
    return < ></>;
  }
  return <SaveBar {...props}>{children}</SaveBar>;
};

export default SaveBarComponent;
