const SaveBar = () => {
  return (
    <ui-save-bar id="custom-save-bar">
      <button variant="primary" id="save-button"></button>
      <button id="discard-button"></button>
    </ui-save-bar>
  );
};

export default SaveBar;
