const planList = {
    plan_1: {
        name: "free",
        content:
            "Access essential features with our Free Plan, perfect for beginners.",
        price: "0",
        color: "#000000",
        backgroundColor: "#F3F3F3",
    },
    plan_2: {
        name: "basic",
        content:
            "Get advanced tools and support with the Standard Plan for individuals.",
        price: "9.9",
        color: "#000000",
        backgroundColor: "#F3F3F3",
    },
    plan_3: {
        name: "pro",
        content:
            "Empower your enterprise with our all-inclusive Enterprise Plan.",
        price: "29",
        color: "#000000",
        backgroundColor: "#F3F3F3",
    },
    plan_4: {
        name: "unlimited",
        content:
            "There is no limit to your growth.",
        price: "99",
        color: "#000000",
        backgroundColor: "#F3F3F3",
    },
};

export default planList;
