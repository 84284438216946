import Http from "../../../../layouts/Http";
import * as Config from "../../../../api/Config";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useEffectAllMedia = () => {
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState({
        all_media: false,
        review_detail: false,
    });
    const [review, setReview] = useState(null);

    const [isLoadingAllMedia, setIsLoadingAllMedia] = useState(true);
    const [isLoadingStatisticsMedias, setIsLoadingStatisticsMedias] = useState(false);

    const [allMedia, setAllMedia] = useState([]);
    const [statisticsMedias, setStatisticsMedias] = useState({
        image: 0,
        video: 0
    });

    const [cursorMedia, setCursorMedia] = useState({
        next_cursor: null,
        prev_cursor: null
    });

    const getReviewDetail = (review_id) => {
        setIsLoading((prev) => ({ ...prev, review_detail: true }));
        Http.get(`${Config.API_URL}/reviews/${review_id}`)
            .then((response) => {
                if (response.data.data) {
                    setReview((prev) => {
                        return { ...prev, ...response.data.data };
                    });
                }
            })
            .catch((e) => {
              window.shopify.toast.show(t("toast.server_error", { ns: "message" }), {
                isError: true,
              });
            })
            .finally(() => {
                setIsLoading((prev) => ({ ...prev, review_detail: false }));
            });
    };

    const getStatisticsMedias = (filters) => {
        setIsLoadingStatisticsMedias(true);
        Http.get(`${Config.API_URL}/reviews/statistics-medias`, {
            params: filters,
        })
        .then((response) => {
            if (response.data) {
                setStatisticsMedias(response.data);
            }
        })
        .catch((e) => {
          window.shopify.toast.show(t("toast.server_error", { ns: "message" }), {
            isError: true,
          });
        })
        .finally(() => {
            setIsLoadingStatisticsMedias(false);
        });
    };

    const [searchParams, setSearchParams] = useSearchParams();

    const getAllMedia = () => {
        const { media_type, product_ids, sort_by, order_by, cursor } = Object.fromEntries(searchParams);

        // Define valid values and defaults
        const validMediaTypes = ['all', 'image', 'video'];
        const validSortBy = ['most_recent', 'review_star', 'product_title'];
        const validOrderBy = ['asc', 'desc'];

        // Validate and set defaults for filters
        const filters = {
            media_type: validMediaTypes.includes(media_type) ? media_type : 'all',
            product_ids: product_ids || '',
            sort_by: validSortBy.includes(sort_by) ? sort_by : 'most_recent',
            order_by: validOrderBy.includes(order_by) ? order_by : 'desc',
        };

        if (cursor) {
            filters.cursor = cursor;
        }

        Http.get(`${Config.API_URL}/reviews/medias`, {
            params: filters,
        })
        .then((response) => {
            if (response.data.data) {
                const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
                const extractedData = response.data.data
                    .filter(item => item.url && urlPattern.test(item.url))
                    .map(item => ({
                        id: item.id,
                        review_id: item.review_id,
                        product_id: item.product_id,
                        name: item.name,
                        star: item.star,
                        url: item.url,
                        video_thumb: item.video_thumb,
                        media_type: item.media_type,
                        index: item.index
                    }));
                setAllMedia(extractedData);
                setCursorMedia({
                    next_cursor: response.data.next_cursor,
                    prev_cursor: response.data.prev_cursor
                });
            }
        })
        .catch((e) => {
          window.shopify.toast.show(t("toast.server_error", { ns: "message" }), {
            isError: true,
          });
        })
        .finally(() => {
            setIsLoadingAllMedia(false);
        });

        getStatisticsMedias({...filters, media_type: 'all'});
    };

    useEffect(() => {
        getAllMedia();
    }, [searchParams]);

    return {
        isLoading,
        isLoadingAllMedia,
        isLoadingStatisticsMedias,
        getReviewDetail,
        review,
        allMedia,
        cursorMedia,
        statisticsMedias
    };
};

export default useEffectAllMedia;
