import { Modal as AppBridgeModal } from "@shopify/app-bridge-react";
import { Modal as PolarisModal } from "@shopify/polaris";
import useMode from "@/hook/useMode";
const ModalComponent = props => {
  const { children = <></> } = props || {};
  const { csMode } = useMode();
  if (csMode) {
    return (
      <>
        {props?.open && (
          <PolarisModal open={props?.open} onClose={() => props?.onHide()}>
            {children}
          </PolarisModal>
        )}
      </>
    );
  }
  return <AppBridgeModal {...props}>{children}</AppBridgeModal>;
};

export default ModalComponent;
