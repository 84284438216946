import React, { lazy } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter } from "react-router-dom";
import { AppProvider, Frame } from "@shopify/polaris";
import App from "./App";
import "@shopify/polaris/build/esm/styles.css";
import "./i18n";
import MainLayout from "./layouts/MainLayout/index";
import en from "@shopify/polaris/locales/en.json";
import "./assets/scss/styles.scss";
import storage  from "./utils/sessionStorage";
const SignIn = lazy(() => import("./pages/SignIn"));
const urlParams = new URLSearchParams(window.location.search);
const host = urlParams.get("host") || storage.getItem("host");
const shop = urlParams.get("shop") || storage.getItem("shop");
const cs_session_token = urlParams.get("cs_session_token") || storage.getItem("cs_session_token");
const embedded = urlParams.get("embedded");
const root = ReactDOM.createRoot(document.getElementById("root"));
window.__CS_TOKEN__ = cs_session_token;
window.__HOST__ = host;
window.__SHOP__ = shop;
window.__EMBEDDED__ = embedded;
if( cs_session_token ) {
  storage.setItem("cs_session_token", cs_session_token);
  storage.setItem("host", host);
  storage.setItem("shop", shop);
}
if (embedded || cs_session_token) {
  root.render(
    <React.Suspense fallback={<></>}>
      <AppProvider host={host} shop={shop} i18n={en}>
        <Provider store={store}>
          <BrowserRouter>
            <MainLayout>
              <Frame>
                <App />
              </Frame>
            </MainLayout>
          </BrowserRouter>
        </Provider>
      </AppProvider>
    </React.Suspense>,
  );
} else {
  root.render(
    <React.StrictMode>
      <React.Suspense fallback={<></>}>
        <AppProvider>
          <SignIn />
        </AppProvider>
      </React.Suspense>
    </React.StrictMode>,
  );
}
