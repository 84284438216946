import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import useMode from "@/hook/useMode";
function Menu() {
  const { csMode } = useMode();
  const [visible, setVisible] = useState(false);
  const handleToggleMenu = useCallback(() => {
    setVisible(val => !val);
  }, []);
  return (
    <>
      {csMode ? (
        <div className={`app-cs-menu ${visible ? "is-active" : ""} `}>
          <div className="app-cs-menu-toggle" onClick={handleToggleMenu}>
            Menu
          </div>
          <div className="app-cs-menu-wrap">
            <Link to="/" rel="home">
              Home
            </Link>
            <Link to="/review-management">Manage Reviews</Link>
            <Link to="/email-review">Email Requests</Link>
            <Link to="/widget">On-site Displays</Link>
            <Link to="/group">Group Product</Link>
            <Link to="/marketing-social">Marketing & Social</Link>
            <Link to="/setting?tab=1">Settings & Pricing</Link>
          </div>
        </div>
      ) : (
        <ui-nav-menu>
          <Link to="/" rel="home">
            Home
          </Link>
          <Link to="/review-management">Manage Reviews</Link>
          <Link to="/email-review">Email Requests</Link>
          <Link to="/widget">On-site Displays</Link>
          <Link to="/group">Group Product</Link>
          <Link to="/marketing-social">Marketing & Social</Link>
          <Link to="/setting?tab=1">Settings & Pricing</Link>
        </ui-nav-menu>
      )}
    </>
  );
}

export default Menu;
