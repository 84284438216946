import { Box, Text, BlockStack, InlineStack, Button } from "@shopify/polaris";
import * as Config from "../../api/Config";
import Http from "../../layouts/Http";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import * as ActionTypes from "../../store/action-types";
import bestChoiceIcon from "../../assets/images/pricing/bestChoice.png";
import { amplitudeButtonClickTracking } from "../../utils/amplitudeButtonTracking";
import { planActiveTracking } from "./../../utils/planActiveTracking";
const PricingCard = ({
    plan,
    disable,
    variant,
    shopInfo,
    t,
    setIsCharging,
    isCharging,
    discount,
    bestChoice,
    contactUs,
    isDowngrade,
    setIsDowngrade,
    setSelectPlan,
    page,
    planActiveTracking,
}) => {
    const dispatch = useDispatch();
    const [primaryBtnContent, setPrimaryBtnContent] = useState(
        t("plan_table.upgrade", { ns: "pricing" })
    );
    const [loading, setIsLoading] = useState(false);
    const handleChangePlan = () => {
        setIsLoading(true);
        setIsCharging(true);
        if (isDowngrade) {
            setIsDowngrade(false);
        }
        planActiveTracking(
            "Setting",
            t(`plan.${plan.name}`, { ns: "pricing" })
        );
        Http.post(`${Config.API_URL}/charge/generate-url`, {
            plan: plan.name,
        })
            .then((res) => {
                if (res.data.status) {
                    if (
                        res.data.data.confirmation_url &&
                        res.data.data.plan !== "free"
                    ) {
                        window.open(res.data.data.confirmation_url, "_top");
                    } else {
                        dispatch({
                            type: ActionTypes.UPDATE_SHOP_INFO,
                            payload: res.data.data,
                        });
                    }
                    setIsLoading(false);
                    setIsCharging(false);
                }
            })
            .catch((e) => {
                console.log(e);
                setIsLoading(false);
                setIsCharging(false);
            });
    };

    const handleContactUs = () => {
        window.$crisp.push(["do", "chat:open"]);
        window.$crisp.push([
            "set",
            "message:text",
            [`I want to known about Ultimate plan`],
        ]);
    };

    useEffect(() => {
        if (disable && !isCharging) {
            setPrimaryBtnContent(
                t("plan_table.current_plan", { ns: "pricing" })
            );
        } else {
            if (contactUs) {
                setPrimaryBtnContent(t("contact_us", { ns: "translation" }));
            } else {
                if (variant === "primary") {
                    if (plan?.name === "free") {
                        if (plan?.name === "free") {
                            setPrimaryBtnContent(
                                t("select_plan", {
                                    ns: "pricing",
                                    plan: t(`plan.for_free`, { ns: "pricing" }),
                                })
                            );
                            return;
                        }
                    }
                    if (
                        shopInfo?.remaining_trial_days &&
                        Number(shopInfo?.remaining_trial_days) > 0
                    ) {
                        if (plan?.name !== "free") {
                            setPrimaryBtnContent(
                                t("plan_table.start_trial", {
                                    ns: "pricing",
                                    day: shopInfo?.remaining_trial_days,
                                })
                            );
                        }
                    } else {
                        setPrimaryBtnContent(
                            t("select_plan", {
                                ns: "pricing",
                                plan: t(`plan.${plan?.name}`, {
                                    ns: "pricing",
                                }),
                            })
                        );
                    }
                } else {
                    setPrimaryBtnContent(
                        t("plan_table.select_plan", { ns: "pricing" })
                    );
                }
            }
        }
    }, [
        t,
        variant,
        disable,
        shopInfo?.charge_status,
        shopInfo?.remaining_trial_days,
    ]);

    return (
        <>
            <div
                style={{
                    width: "32%",
                    minWidth: "200px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
                className="pricing-card--container"
            >
                <div
                    style={{
                        maxHeight: "28px",
                        borderRadius: "8px 8px 0 0",
                        padding: "6px 0",
                        height: "28px",
                        backgroundColor: bestChoice ? "#E0F0FF" : "transparent",
                    }}
                >
                    {bestChoice && (
                        <div
                            style={{
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "10px",
                            }}
                        >
                            <img src={bestChoiceIcon} alt="best choice icon" />
                            <p
                                style={{
                                    color: "#00527C",
                                    fontSize: "13px",
                                    fontWeight: 650,
                                }}
                            >
                                {t("best_choice", { ns: "pricing" })}
                            </p>
                        </div>
                    )}
                </div>
                <div
                    style={{
                        width: "100%",
                        minWidth: "148px",
                        height: "100%",
                        "--pc-box-overflow-x": "clip",
                        "--pc-box-overflow-y": "clip",
                        "--pc-box-padding-block-start-xs": "var(--p-space-400)",
                        "--pc-box-padding-block-end-xs": "var(--p-space-400)",
                        "--pc-box-padding-inline-start-xs":
                            "var(--p-space-400)",
                        "--pc-box-padding-inline-end-xs": "var(--p-space-400)",
                        "--pc-shadow-bevel-z-index": "32",
                        "--pc-shadow-bevel-content-xs": '""',
                        "--pc-shadow-bevel-box-shadow-xs":
                            "var(--p-shadow-100)",
                        "--pc-shadow-bevel-border-radius-xs":
                            "var(--p-border-radius-300)",
                        backgroundColor: plan.backgroundColor,
                        display: "flex",
                        flexDirection: "column",
                    }}
                    className={`pricing-card ${
                        bestChoice ? "best-choice" : ""
                    }`}
                >
                    <Box paddingBlock={200}>
                        <p
                            style={{
                                textAlign: "center",
                                fontSize: "24px",
                                lineHeight: "32px",
                                fontWeight: "700",
                                color: plan.color,
                            }}
                        >
                            {t(`plan.${plan.name}`, { ns: "pricing" })}
                        </p>
                    </Box>
                    <hr
                        style={{
                            margin: "0",
                            borderBlockStart:
                                "var(--p-border-width-050) solid var(--p-color-bg-surface-tertiary-active)",
                        }}
                    />
                    <div
                        className="Polaris-BlockStack"
                        style={{
                            "--pc-block-stack-align": "space-between",
                            "--pc-block-stack-order": "column",
                            "--pc-block-stack-gap-xs": "var(--p-space-600)",
                            paddingTop: "24px",
                            flex: 1,
                        }}
                    >
                        <Text>
                            {t(`plan_table.pricing_card.${plan.name}`, {
                                ns: "pricing",
                            })}
                        </Text>
                        <BlockStack gap={600}>
                            {discount &&
                                !isNaN(plan.price) &&
                                Number(plan.price) !== 0 && (
                                    <span
                                        style={{
                                            fontWeight: 650,
                                            fontSize: "13px",
                                            display: "flex",
                                            alignItems: "flex-end",
                                            color: "#303030",
                                        }}
                                    >
                                        {t(`origin_price`, {
                                            ns: "pricing",
                                        })}
                                        &nbsp;
                                        <span
                                            style={{
                                                textDecoration: "line-through",
                                            }}
                                        >
                                            ${plan.price}
                                        </span>
                                        &nbsp;
                                        <span
                                            style={{
                                                color: "#df3e38",
                                                fontWeight: 500,
                                            }}
                                        >
                                            ({discount}%{" "}
                                            {t("off", { ns: "pricing" })})
                                        </span>
                                    </span>
                                )}
                            <Box>
                                {Number(plan.price) ? (
                                    <InlineStack
                                        wrap={false}
                                        blockAlign="baseline"
                                        align="center"
                                    >
                                        <span
                                            style={{
                                                fontWeight: 300,
                                                fontSize: "30px",
                                                display: "flex",
                                                alignItems: "flex-start",
                                                color: "#737373",
                                                position: "relative",
                                                top: "-10px",
                                            }}
                                        >
                                            $
                                        </span>
                                        <span
                                            style={{
                                                fontWeight: 700,
                                                fontSize: "36px",
                                                display: "flex",
                                                alignItems: "flex-end",
                                                lineHeight: "100%",
                                                color: plan.color,
                                            }}
                                        >
                                            {(
                                                (Number(plan.price) *
                                                    (100 - discount)) /
                                                100
                                            ).toFixed(1)}
                                        </span>
                                        {!discount && (
                                            <span
                                                style={{
                                                    fontWeight: 450,
                                                    fontSize: "13px",
                                                    display: "flex",
                                                    alignItems: "flex-end",
                                                    color: plan.color,
                                                }}
                                            >
                                                {t(`per_month`, {
                                                    ns: "pricing",
                                                })}
                                            </span>
                                        )}
                                    </InlineStack>
                                ) : (
                                    <span
                                        style={{
                                            fontWeight: 450,
                                            fontSize: !isNaN(plan.price)
                                                ? "36px"
                                                : "30px",
                                            display: "flex",
                                            alignItems: "flex-end",
                                            justifyContent: "center",
                                            color: plan.color,
                                        }}
                                    >
                                        {t("custom", { ns: "translation" })}
                                    </span>
                                )}
                                {discount &&
                                    !isNaN(plan.price) &&
                                    Number(plan.price) !== 0 && (
                                        <span
                                            style={{
                                                fontWeight: 450,
                                                fontSize: "13px",
                                                color: plan.color,
                                                display: "block",
                                                textAlign: "center",
                                            }}
                                        >
                                            {t(`discount_offer_time`, {
                                                ns: "pricing",
                                                time: `2 ${t("month", {
                                                    ns: "translation",
                                                })}`,
                                            })}
                                        </span>
                                    )}
                            </Box>
                            <Box>
                                <Button
                                    disabled={disable}
                                    variant={variant}
                                    fullWidth
                                    onClick={() => {
                                        amplitudeButtonClickTracking(
                                            page,
                                            t(`plan.${plan.name}`, {
                                                ns: "pricing",
                                                lng: "en",
                                            }) + "-plan"
                                        );
                                        planActiveTracking(
                                            page,
                                            t(`plan.${plan.name}`, {
                                                ns: "pricing",
                                                lng: "en",
                                            })
                                        );
                                        !contactUs
                                            ? primaryBtnContent ===
                                              t("plan_table.select_plan", {
                                                  ns: "pricing",
                                              })
                                                ? (() => {
                                                      setIsDowngrade(true);
                                                      setSelectPlan(plan?.name);
                                                  })()
                                                : handleChangePlan(plan?.name)
                                            : handleContactUs();
                                    }}
                                    loading={loading}
                                >
                                    {primaryBtnContent}
                                </Button>
                            </Box>
                        </BlockStack>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PricingCard;
