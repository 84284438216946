import { useAppBridge } from "@shopify/app-bridge-react";
import useMode from "./useMode";
const useAppBridgeHook = () => {
  const shopify = useAppBridge();
  const { csMode } = useMode();
  if (csMode) {
    return {
      loading: () => {},
      saveBar: {
        hide: () => {},
        show: () => {},
        toggle: () => {},
        leaveConfirmation: () => new Promise(resolve => resolve()),
      },
      toast: {
        show: () => {},
      },
      config: {
        apiKey: "",
        disabledFeatures: [],
        host: "",
        shop: "",
        locale: "",
      },
      resourcePicker: () => new Promise(resolve => resolve()),
      modal:{
        show: (id) => {},
        hide: (id) => {},
        toggle: (id) => {},
      }
    };
  }
  return shopify;
};
export default useAppBridgeHook;
