const listQuestions = [
    {
        title: "How is the request reviews quota calculated?",
        subtitle:
            "The quota is based on the number of orders. If you enable all three types of emails—Request Reviews, Review Reminder, and Thank You. Only one quota will be counted per order.",
    },
    {
        title: "Will my quota be used if I don’t send review requests through TrustShop?",
        subtitle:
            "No, your quota will only be deducted if you utilize TrustShop’s review request feature. If you don’t use this feature, your quota remains unaffected.",
    },
    {
        title: "Can I downgrade my plan?",
        subtitle:
            "Yes, you can downgrade your plan at any time if you no longer need the paid features. Just make sure to disable all paid features before downgrading. If you need assistance, our support team is always here to help.",
    },
    {
        title: "Will I be charged during the trial period?",
        subtitle:
            "No, you won’t be charged during the trial. Charges will only begin once the trial period ends.",
    },
];

export default listQuestions;
