import { Page } from "@shopify/polaris";
import { useNavigate } from "react-router-dom";
import MediaList from "./MediaList";
import MediaReviewDetail from "./MediaReviewDetail";
import { useEffect, useState } from "react";
import useEffectAllMedia from "./hooks/useEffectAllMedia";
import useCallbackAllMedia from "./hooks/useCallbackAllMedia";
import { useTranslation } from "react-i18next";
import useDynamicTitle from "../../../layouts/MainLayout/useDynamicTitle";
import { useSelector } from "react-redux";
import { isShowMedia } from "../../../components/Ultils/common";
import PricingPage from "../../../components/Pricing/PricingPage";
const AllMedia = () => {
    useDynamicTitle("All media");
    const { t } = useTranslation("review-management");
    const navigate = useNavigate();
    const shopInfo = useSelector((state) => state?.Auth?.shopInfo);
    const [showPricing, setShowPricing] = useState(false);
    const { isLoadingAllMedia, allMedia, cursorMedia, statisticsMedias } =
        useEffectAllMedia();

    const [selectedMedia, setSelectedMedia] = useState({});
    const [showDetailModal, setShowDetailModal] = useState(true);
    const { isLoading, review, deleteMedias, deleteReview } =
        useCallbackAllMedia();
    const [currentImg, setCurrentImg] = useState(0);

    useEffect(() => {
        setCurrentImg(0);
    }, [review]);

    useEffect(() => {
        if (!review) return;
        const selectedMediaIndex = review.medias.findIndex(
            (item) => Number(item.id) === selectedMedia.id
        );
        setCurrentImg(selectedMediaIndex >= 0 ? selectedMediaIndex : 0);
    }, [selectedMedia, review]);

    const handleBackActions = () => {
        navigate("/review-management");
    };

    if (isLoadingAllMedia) {
        return <div>Loading...</div>;
    }

    return (
        <>
            <div
                style={{
                    maxWidth: "1000px",
                    margin: "auto",
                }}
                className="all-media-page--container"
            >
                <Page
                    title={t("all_media_page.title", {
                        ns: "review-management",
                    })}
                    fullWidth
                    backAction={{
                        content: "Back",
                        onAction: handleBackActions,
                    }}
                >
                    <div className="all-media-page--wrap">
                        <MediaList
                            medias={allMedia}
                            setCurrentImg={setCurrentImg}
                            review={review}
                            isLoading={isLoading}
                            cursorMedia={cursorMedia}
                            statisticsMedias={statisticsMedias}
                            onDeleteMedia={deleteMedias}
                            shopInfo={shopInfo}
                            limitMedia={!isShowMedia(shopInfo)}
                            selectedMedia={selectedMedia}
                            setSelectedMedia={setSelectedMedia}
                            showDetailModal={showDetailModal}
                            setShowDetailModal={setShowDetailModal}
                        />
                        <MediaReviewDetail
                            t={t}
                            currentImg={currentImg}
                            setCurrentImg={setCurrentImg}
                            review={review}
                            isLoading={isLoading}
                            onDeleteReview={deleteReview}
                            onDeleteMedia={deleteMedias}
                            limitMedia={!isShowMedia(shopInfo)}
                            selectedMedia={selectedMedia}
                            setShowPricing={setShowPricing}
                            showDetailModal={showDetailModal}
                            setShowDetailModal={setShowDetailModal}
                        />
                    </div>
                </Page>
            </div>
            {showPricing && (
                <div className="pricing-modal--container">
                    <div className="pricing-modal--wrap">
                        <PricingPage
                            isModal={true}
                            setShowPricing={setShowPricing}
                            shopInfo={shopInfo}
                            page={"All-media"}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default AllMedia;
