export const planActiveTracking = (page_name, active_plan) => {
    if (!window.amplitude) {
        return;
    }
    window.amplitude.track(`plan_actived`, {
        event_type: "plan_actived",
        page: page_name,
        active_plan: `${active_plan}_plan_actived`,
        groups: "Plan Active",
        category: "Plan Active"
    });
    window.amplitude.flush();
};
