export const amplitudeButtonClickTracking = (page_name, button_name) => {
    if (!window.amplitude) {
        return;
    }
    window.amplitude.track(`button_clicked`, {
        event_type: "button_clicked",
        page: page_name,
        button_name: button_name,
        groups: "Button Clicked",
        category: "Button Clicked"
    });
    window.amplitude.flush();
};
