import { BlockStack, Button, Spinner, Text } from "@shopify/polaris";
import noReviewSelected from "../../../assets/images/review-management/all-media-no-review-selected.svg";
import noReviewSelectedLimit from "../../../assets/images/review-management/all-media-no-review-selected-limit.svg";
import CustomerInfoCard from "./../ReviewDetail/CustomerInfoCard";
import ReviewDetail from "./ReviewDetail";
import { Trans } from "react-i18next";
import { ExternalIcon, XIcon } from "@shopify/polaris-icons";
import { useEffect } from "react";
const MediaReviewDetail = ({
    t,
    currentImg,
    setCurrentImg,
    review,
    isLoading,
    onDeleteReview,
    onDeleteMedia,
    limitMedia,
    setShowPricing,
    selectedMedia,
    showDetailModal,
    setShowDetailModal,
}) => {
    useEffect(() => {
        if (isLoading.review_detail || review) {
            setShowDetailModal(true);
        }
    }, [review, isLoading.review_detail, selectedMedia]);
    return (
        <div
            className={`all-media-page__secondary-section ${
                !showDetailModal ? "hide-section " : ""
            } `}
        >
            <Button
                id="all-media-page__secondary-section-close--btn"
                icon={XIcon}
                variant="monochromePlain"
                onClick={() => setShowDetailModal(!showDetailModal)}
            />
            <div className="all-media-page__secondary-section--wrap">
                {!isLoading.review_detail ? (
                    review ? (
                        <BlockStack gap={400}>
                            <ReviewDetail
                                t={t}
                                review={review}
                                currentImg={currentImg}
                                setCurrentImg={setCurrentImg}
                                onDeleteReview={onDeleteReview}
                                onDeleteMedia={onDeleteMedia}
                                limitMedia={limitMedia}
                                setShowPricing={setShowPricing}
                            />
                            <CustomerInfoCard review={review} t={t} />
                        </BlockStack>
                    ) : (
                        <div
                            style={{
                                height: "60vh",
                                maxHeight: "550px",
                                display: "grid",
                                placeContent: "center",
                                placeItems: "center",
                                backgroundColor: "#FFFFFF",
                                borderRadius: "8px",
                                gap: "8px",
                            }}
                        >
                            <img
                                src={
                                    limitMedia
                                        ? noReviewSelectedLimit
                                        : noReviewSelected
                                }
                                alt=""
                            />
                            {limitMedia ? (
                                <Text id="all-media-page__empty-media-detail">
                                    {" "}
                                    <Trans
                                        i18nKey={
                                            "all_media_page.empty_media_detail_limit"
                                        }
                                        ns="review-management"
                                        components={{
                                            upgrade: (
                                                <Button
                                                    variant="plain"
                                                    icon={ExternalIcon}
                                                    onClick={() =>
                                                        setShowPricing(true)
                                                    }
                                                />
                                            ),
                                        }}
                                    />
                                </Text>
                            ) : (
                                <Text>
                                    {t("all_media_page.empty_media_detail", {
                                        ns: "review-management",
                                    })}
                                </Text>
                            )}
                        </div>
                    )
                ) : (
                    <div
                        style={{
                            height: "60vh",
                            maxHeight: "550px",
                            display: "grid",
                            placeContent: "center",
                            placeItems: "center",
                            backgroundColor: "#FFFFFF",
                            borderRadius: "8px",
                            gap: "8px",
                        }}
                    >
                        <Spinner />
                    </div>
                )}
            </div>
        </div>
    );
};

export default MediaReviewDetail;
