import {
    BlockStack,
    Box,
    Icon,
    InlineStack,
    Text,
    Button,
    Tooltip,
    InlineGrid,
} from "@shopify/polaris";
import { CheckIcon, AlertCircleIcon } from "@shopify/polaris-icons";
import { Fragment } from "react";
import { amplitudeButtonClickTracking } from "../../utils/amplitudeButtonTracking";
const freePlanFunc = [
    "free_banner.email_quota_limit",
    "free_banner.basic_access",
    "free_banner.basic_edit_email",
    "free_banner.upload_media",
    "free_banner.quick_chat",
    // "free_banner.visible_brand",
];

const BannerFreePlan = ({
    t,
    current_plan,
    setIsDowngrade,
    setSelectPlan,
    page,
}) => {
    return (
        <div className="banner-free--plan">
            <Box
                padding={400}
                borderColor="border"
                borderWidth="0165"
                borderRadius="300"
            >
                <InlineGrid
                    gap={200}
                    columns={{
                        xl: "1fr 2fr",
                        lg: "1fr 2fr",
                        md: 2,
                        sm: 1,
                        xs: 1,
                    }}
                >
                    <BlockStack>
                        <Text variant="headingLg">
                            {t("plan.free_plan", { ns: "pricing" })} - $0
                        </Text>
                        <Box>
                            <Text>
                                {t("free_banner.title", { ns: "pricing" })}
                            </Text>
                        </Box>
                        <Box paddingBlockStart={100}>
                            <Button
                                disabled={current_plan === "free"}
                                variant={
                                    current_plan === "free"
                                        ? "primary"
                                        : "secondary"
                                }
                                tone={
                                    current_plan !== "free"
                                        ? "critical"
                                        : "null"
                                }
                                onClick={() => {
                                    amplitudeButtonClickTracking(
                                        page,
                                        "Downgrade"
                                    );
                                    setIsDowngrade(true);
                                    setSelectPlan("free");
                                }}
                            >
                                {current_plan === "free"
                                    ? t("plan_table.current_plan", {
                                          ns: "pricing",
                                      })
                                    : t("plan_table.downgrade", {
                                          ns: "pricing",
                                      })}
                            </Button>
                        </Box>
                    </BlockStack>
                    <div
                        style={{
                            flex: 1,
                        }}
                    >
                        <InlineGrid
                            gap={100}
                            columns={{
                                xl: 2,
                                lg: 2,
                                md: 1,
                                sm: 1,
                                xs: 1,
                            }}
                        >
                            <BlockStack gap={100}>
                                {freePlanFunc.map((item, index) => (
                                    <Fragment key={item}>
                                        {index < 4 && (
                                            <Box>
                                                <InlineStack
                                                    gap={100}
                                                    wrap={false}
                                                    align="start"
                                                >
                                                    <Box>
                                                        <Icon
                                                            source={CheckIcon}
                                                        />
                                                    </Box>
                                                    <Text>
                                                        {t(item, {
                                                            ns: "pricing",
                                                            limit: "50",
                                                        })}
                                                    </Text>
                                                    {index === 0 && (
                                                        <Tooltip
                                                            content={t(
                                                                `plan_table.primary_collapse.email_quota_helptext`,
                                                                {
                                                                    ns: "pricing",
                                                                }
                                                            )}
                                                            zIndexOverride={
                                                                1000
                                                            }
                                                        >
                                                            <Icon
                                                                source={
                                                                    AlertCircleIcon
                                                                }
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </InlineStack>
                                            </Box>
                                        )}
                                    </Fragment>
                                ))}
                            </BlockStack>
                            <Box>
                                <InlineStack
                                    gap={100}
                                    wrap={false}
                                    align="start"
                                >
                                    <Box>
                                        <Icon source={CheckIcon} />
                                    </Box>
                                    <Text>
                                        {t(freePlanFunc[4], {
                                            ns: "pricing",
                                            limit: "50",
                                        })}
                                    </Text>
                                </InlineStack>
                            </Box>
                        </InlineGrid>
                    </div>
                </InlineGrid>
            </Box>
        </div>
    );
};
export default BannerFreePlan;
