import React, { useEffect, lazy, Suspense, useCallback, useState } from "react";
import Http from "../Http";
import * as Config from "../../api/Config";
import * as Actions from "../../store/actions";
import * as ActionTypes from "../../store/action-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Menu from "./Menus";
import { useNavigate, useLocation } from "react-router";
import { useMemo } from "react";
import SaveBar from "./SaveBar";
const ModalBlog = lazy(() => import("../../components/ModalBlog"));
const MainLayout = ({ children }) => {
  const { t } = useTranslation("pricing");
  const navigate = useNavigate();
  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const authStore = useSelector(state => state?.Auth);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [showModalBlog, setShowModalBlog] = useState(false);
  const handleShowModal = useCallback(() => {
    let flag = window.localStorage.getItem("show-modal-blog");
    if (!flag) {
      setShowModalBlog(true);
    } else {
      const timestamp = new Date(flag);
      const currentTime = new Date();
      const diff = currentTime - timestamp;
      if (diff > 45 * 24 * 60 * 60 * 1000) {
        setShowModalBlog(true);
      } else {
        setShowModalBlog(false);
      }
    }
  }, []);

  const handleAmplitudeUserInfo = shopInfo => {
    if (!window.amplitude) return;
    try {
      window.amplitude.setUserId(shopInfo.domain);
      const identifyEvent = new window.amplitude.Identify();
      identifyEvent.set(
        "Current Plan",
        t(`plan.${shopInfo.current_plan}`, {
          ns: "pricing",
          lng: "en",
        }) || "None",
      );
      window.amplitude.identify(identifyEvent);
    } catch (e) {
      throw e;
    }
  };

  const handleNullAccessToken = async () => {
    //if (!window?.shopify?.idToken) {
    //  return;
    //}
    const token = window.__CS_TOKEN__ ?? (await window?.shopify?.idToken());
    if (!token) return;
    dispatch(Actions.authLogin({ access_token: token }));
    try {
      const response = await Http.get(`${Config.API_URL}/shop`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
      });
      if (response.status) {
        dispatch(
          Actions.authLogin({
            access_token: response.data.data.app_token,
            user: {
              shop_domain: response.data.data.myshopify_domain,
              shop_id: response.data.data.id,
              name: response.data.data.name,
              email: response.data.data.email,
              uuid: response.data.data.uuid,
              currency: response.data.data.currency,
              country_code: response.data.data.country_code,
              scope: response.data.data.scope,
            },
          }),
        );
        dispatch({
          type: ActionTypes.UPDATE_SHOP_INFO,
          payload: response.data.data,
        });

        handleAmplitudeUserInfo(response.data.data);

        if (response.data.data.name && response.data.data.email) {
          window.$crisp = [];
          window.$crisp.push(["do", "session:reset"]);
          window.CRISP_TOKEN_ID = response.data.data.uuid;
          window.CRISP_WEBSITE_ID = "92ef7a3e-efdf-4313-9626-d892cb21ff85";
          window.$crisp.push(["set", "user:nickname", response.data.data.name]);
          window.$crisp.push(["set", "user:email", response.data.data.email]);
          const script = document.createElement("script");
          script.src = "https://client.crisp.chat/l.js";
          script.async = 1;
          document.getElementsByTagName("head")[0].appendChild(script);
        }

        if (!response.data.data.on_boarding) {
          handleShowModal();
        }
      }
    } catch (error) {
      window.shopify.toast.show(t("toast.server_error", { ns: "message" }), {
        isError: true,
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!authStore?.access_token) {
      handleNullAccessToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStore?.access_token]);

  // navigate to onboarding when enable onboarding and current path is not onboarding route
  useEffect(() => {
    if (authStore.shopInfo.on_boarding && location.pathname !== "/onboarding") {
      navigate("/onboarding");
    }
  }, [authStore.shopInfo.on_boarding, location.pathname]);

  // condition to show NavMenu
  const showMenu = useMemo(() => {
    return !authStore.shopInfo.on_boarding;
  }, [authStore.shopInfo.on_boarding]);
  return (
    <>
      {loading ? (
        <></>
      ) : (
        <>
          {showMenu && <Menu />}
          {authStore?.access_token && <>{children}</>}
          {showModalBlog &&
            authStore?.user?.email &&
            !authStore?.user?.email?.includes("shopify.com") && (
              <Suspense fallback="">
                <ModalBlog setShowModalBlog={setShowModalBlog} />
              </Suspense>
            )}
          <SaveBar />
        </>
      )}
    </>
  );
};

export default MainLayout;
