import { Button, Text, Tooltip } from "@shopify/polaris";
import "./LimitMediaOverlay.scss";
import { Trans } from "react-i18next";
const LimitMediaOverlay = ({ iconWidth = "12px", iconHeight = "14px" }) => {
    return (
        <div className="limit-media--overlay">
            <Tooltip
                content={
                    <Text>
                        <Trans
                            i18nKey={"upgrade_to_show_media"}
                            ns="review-management"
                            components={{
                                upgrade: <Button variant="monochromePlain" />,
                            }}
                        />
                    </Text>
                }
                preferredPosition="mostSpace"
                zIndexOverride={99999999}
            >
                <div className="limit-media--wrap">
                    <svg
                        width="12"
                        height="14"
                        viewBox="0 0 12 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                            width: iconWidth,
                            height: iconHeight,
                        }}
                    >
                        <path
                            d="M6.75 10.0493C7.19835 9.78997 7.5 9.30521 7.5 8.75C7.5 7.92157 6.82843 7.25 6 7.25C5.17157 7.25 4.5 7.92157 4.5 8.75C4.5 9.30521 4.80165 9.78997 5.25 10.0493V10.5C5.25 10.9142 5.58579 11.25 6 11.25C6.41421 11.25 6.75 10.9142 6.75 10.5V10.0493Z"
                            fill="white"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M2.25 4.09451V3.75C2.25 1.67893 3.92893 0 6 0C8.07107 0 9.75 1.67893 9.75 3.75V4.09451C11.0439 4.42755 12 5.60212 12 7V11C12 12.6569 10.6569 14 9 14H3C1.34315 14 0 12.6569 0 11V7C0 5.60212 0.95608 4.42755 2.25 4.09451ZM3.75 3.75C3.75 2.50736 4.75736 1.5 6 1.5C7.24264 1.5 8.25 2.50736 8.25 3.75V4H3.75V3.75ZM1.5 7C1.5 6.17157 2.17157 5.5 3 5.5H9C9.82843 5.5 10.5 6.17157 10.5 7V11C10.5 11.8284 9.82843 12.5 9 12.5H3C2.17157 12.5 1.5 11.8284 1.5 11V7Z"
                            fill="white"
                        />
                    </svg>
                </div>
            </Tooltip>
        </div>
    );
};

export default LimitMediaOverlay;
