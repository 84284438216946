import {
    BlockStack,
    Box,
    Button,
    ButtonGroup,
    Icon,
    InlineStack,
    Pagination,
    Tabs,
    Text,
} from "@shopify/polaris";
import MediaItem from "./MediaItem";
import { useState, useCallback } from "react";
import { FilterIcon, MinusIcon } from "@shopify/polaris-icons";
import SingleSort from "../../../components/Sort/SingleSort";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomModalMessage from "../../../components/CustomModalMessage";
import emptyState from "../../../assets/images/review-empty-state.svg";

const MediaList = ({
    medias = [],
    statisticsMedias,
    cursorMedia,
    onDeleteMedia,
    limitMedia,
    selectedMedia,
    setSelectedMedia,
    showDetailModal,
    setShowDetailModal,
}) => {
    const navigate = useNavigate("");
    const { t } = useTranslation("translation");

    const [showModal, setShowModal] = useState(null);
    const [deleteMediaId, setDeleteMediaId] = useState(null);
    const [listSelected, setListSelected] = useState([]);
    const [selected, setSelected] = useState(0);
    const [filter, setFilter] = useState({
        product_ids: [],
        sort_by: "",
    });

    const [sortBy, setSortBy] = useState("most_recent");

    const SORT_MAPPING = {
        product_title_asc: { sort_by: "product_title", order_by: "asc" },
        product_title_desc: { sort_by: "product_title", order_by: "desc" },
    };

    const [searchParams, setSearchParams] = useSearchParams();

    const setSearchParam = useCallback(
        (key, value) => {
            searchParams.set(key, value);
            setSearchParams(searchParams);
        },
        [searchParams, setSearchParams]
    );

    const tabs = ["all", "photo", "video"].map((item, index) => {
        const mediaType = ["all", "image", "video"][index];

        let count = statisticsMedias[mediaType] || 0;

        if (mediaType === "all") {
            count =
                (statisticsMedias["image"] || 0) +
                (statisticsMedias["video"] || 0);
        }

        return {
            content: t(item, { ns: "translation" }),
            index,
            onAction: () => {},
            id: `${item}-${index}`,
            isLocked: index === 0,
            actions: [],
            badge: count.toString(),
        };
    });

    const handleCancel = () => {
        setShowModal(null);
    };

    const handleDelete = () => {
        const media_ids =
            showModal === "delete_media"
                ? [deleteMediaId]
                : listSelected.map(({ id }) => id);

        onDeleteMedia(media_ids);
        setShowModal(null);
        setListSelected(showModal === "delete_medias" ? [] : listSelected);
    };

    const handleDeleteMediaReview = (media_id) => {
        setDeleteMediaId(media_id);
        setShowModal("delete_media");
    };

    const handleDeleteMediaReviews = () => {
        setShowModal("delete_medias");
    };

    const handleFilterLabel = (listFilter, key, defaultLabel) => {
        const label = [];
        listFilter.forEach((item) => {
            if (filter[key].includes(item.value)) label.push(item.label);
        });
        if (label.length > 0) {
            return label.join(", ");
        } else {
            return defaultLabel;
        }
    };

    const handleRemoveAll = () => {
        setListSelected([]);
    };
    const handleSelectAllMedia = () => {
        // const listMediaId = medias.map((item) => item.id) || [];
        setListSelected(medias);
    };

    // handle select tab
    const handleSelectTab = (value) => {
        setSelected(value);
        handleSelectMediaType(value);
    };

    // handle set filter media type when select tab
    const handleSelectMediaType = (indexTab) => {
        setSearchParam("media_type", ["all", "image", "video"][indexTab]);
    };

    const handleSelectSortBy = (value) => {
        setSortBy(value);

        const params = SORT_MAPPING[value] || { sort_by: value };
        Object.entries(params).forEach(([key, val]) =>
            setSearchParam(key, val)
        );
    };

    const handleChangeProduct = async () => {
        const selectedProductIds = searchParams.get("product_ids") || "";
        const initialSelection = selectedProductIds
            ? selectedProductIds
                  .split(",")
                  .map((id) => ({ id: `gid://shopify/Product/${id}` }))
            : [];

        const selected = await window.shopify.resourcePicker({
            type: "product",
            multiple: true,
            action: "select",
            filter: {
                hidden: false,
                variants: false,
                draft: true,
                archived: true,
            },
            selectionIds: initialSelection,
        });
        if (selected) {
            const productIds = selected
                .map((item) => item.id.split("/").pop())
                .join(",");

            setSearchParam("product_ids", productIds);
        }
    };
    const handleGetMediaIndex = (mediaId) => {
        const group = medias.filter(
            (el) => Number(el.review_id) === Number(selectedMedia.review_id)
        );
        const index = group.findIndex((el) => el.id === mediaId);
        if (index >= 0) {
            return `${index + 1}/${group.length}`;
        }
        return "";
    };
    return (
        <>
            <div className="all-media-page__primary-section">
                <Box
                    borderRadius="200"
                    background="bg-fill"
                    id="all-media-page__primary--wrap"
                >
                    <div
                        style={{
                            overflowX: "auto",
                            scrollbarWidth: "thin",
                            padding: "0 8px 0 0",
                        }}
                    >
                        <InlineStack
                            gap={400}
                            blockAlign="center"
                            align="space-between"
                            wrap={false}
                        >
                            <Tabs
                                tabs={tabs}
                                selected={selected}
                                onSelect={(value) => handleSelectTab(value)}
                            />
                            <InlineStack
                                gap={200}
                                blockAlign="center"
                                wrap={false}
                            >
                                <Button
                                    onClick={() => handleChangeProduct()}
                                    icon={FilterIcon}
                                ></Button>
                                <SingleSort
                                    checked={sortBy}
                                    setChecked={handleSelectSortBy}
                                />
                            </InlineStack>
                        </InlineStack>
                    </div>
                    {medias.length > 0 ? (
                        <>
                            <Box
                                paddingBlock={
                                    listSelected.length > 0 ? 100 : 200
                                }
                                paddingInline={400}
                                borderColor="border"
                                borderBlockStartWidth="0165"
                                borderBlockEndWidth="0165"
                                background="bg-surface-secondary"
                            >
                                <InlineStack
                                    gap={400}
                                    blockAlign="center"
                                    align="space-between"
                                >
                                    {listSelected.length > 0 ? (
                                        <>
                                            <InlineStack
                                                gap={100}
                                                align="start"
                                                blockAlign="center"
                                            >
                                                <div
                                                    style={{
                                                        borderRadius: "4px",
                                                        backgroundColor:
                                                            "#303030",
                                                        width: "16px",
                                                        height: "16px",
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                        alignItems: "center",
                                                        color: "white",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={handleRemoveAll}
                                                >
                                                    <Icon source={MinusIcon} />
                                                </div>
                                                <Text
                                                    tone="subdued"
                                                    fontWeight="semibold"
                                                >
                                                    {t(
                                                        "all_media_page.media_selected",
                                                        {
                                                            ns: "review-management",
                                                            number: listSelected.length,
                                                        }
                                                    )}
                                                </Text>
                                                <Button
                                                    variant="plain"
                                                    onClick={
                                                        handleSelectAllMedia
                                                    }
                                                >
                                                    {t(
                                                        "all_media_page.select_all_media",
                                                        {
                                                            ns: "review-management",
                                                        }
                                                    )}
                                                </Button>
                                            </InlineStack>
                                            <Button
                                                onClick={() =>
                                                    handleDeleteMediaReviews()
                                                }
                                            >
                                                {t(
                                                    "all_media_page.delete_selected_img",
                                                    {
                                                        ns: "review-management",
                                                    }
                                                )}
                                            </Button>
                                        </>
                                    ) : (
                                        <Text tone="subdued">
                                            {t(
                                                "all_media_page.all_media_in_store",
                                                {
                                                    ns: "review-management",
                                                }
                                            )}
                                        </Text>
                                    )}
                                </InlineStack>
                            </Box>
                            <div className="all-media-list--container">
                                <div
                                    className="all-media-list--wrap"
                                    gap={200}
                                    wrap={true}
                                >
                                    {medias.map((item, index) => (
                                        <MediaItem
                                            t={t}
                                            key={`media-list-item--${item.id}`}
                                            mediaItem={item}
                                            mediaIndex={index}
                                            handleGetMediaIndex={
                                                handleGetMediaIndex
                                            }
                                            selectedMedia={selectedMedia}
                                            setSelectedMedia={setSelectedMedia}
                                            listSelected={listSelected}
                                            setListSelected={setListSelected}
                                            onDeleteMediaReview={
                                                handleDeleteMediaReview
                                            }
                                            limitMedia={limitMedia}
                                            showDetailModal={showDetailModal}
                                            setShowDetailModal={
                                                setShowDetailModal
                                            }
                                        />
                                    ))}
                                </div>
                            </div>
                            <div
                                style={{
                                    position: "sticky",
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    padding: "5px 10px",
                                    display: "flex",
                                    justifyContent: "center",
                                    backgroundColor: "var(--p-color-bg-fill)",
                                    zIndex: 99,
                                    borderRadius: "0px 0px 12px 12px",
                                    boxShadow: "#0000000E 0px -6px 5px -3px",
                                }}
                            >
                                <Pagination
                                    hasNext={cursorMedia.next_cursor}
                                    hasPrevious={cursorMedia.prev_cursor}
                                    onNext={() => {
                                        setSearchParam(
                                            "cursor",
                                            cursorMedia.next_cursor
                                        );
                                    }}
                                    onPrevious={() => {
                                        setSearchParam(
                                            "cursor",
                                            cursorMedia.prev_cursor
                                        );
                                    }}
                                />
                            </div>
                        </>
                    ) : (
                        <Box
                            background="bg-fill"
                            width="100%"
                            paddingInline={200}
                        >
                            <div
                                style={{
                                    height: "60vh",
                                    maxHeight: "550px",
                                    display: "grid",
                                    placeContent: "center",
                                }}
                            >
                                <BlockStack gap={200} inlineAlign="center">
                                    <img src={emptyState} alt="" />
                                    <Text fontWeight="semibold">
                                        {t("all_media_page.empty.title", {
                                            ns: "review-management",
                                        })}
                                    </Text>
                                    <Text>
                                        {t("all_media_page.empty.subtitle", {
                                            ns: "review-management",
                                        })}
                                    </Text>
                                    <ButtonGroup>
                                        <Button
                                            variant="secondary"
                                            onClick={() =>
                                                navigate(
                                                    "/email-review/campaign"
                                                )
                                            }
                                        >
                                            {t(
                                                "all_media_page.empty.set_request_email",
                                                {
                                                    ns: "review-management",
                                                }
                                            )}
                                        </Button>
                                        <Button
                                            variant="primary"
                                            onClick={() =>
                                                navigate(
                                                    "/review-management/import-review"
                                                )
                                            }
                                        >
                                            {t(
                                                "all_media_page.empty.import_review",
                                                {
                                                    ns: "review-management",
                                                }
                                            )}
                                        </Button>
                                    </ButtonGroup>
                                </BlockStack>
                            </div>
                        </Box>
                    )}
                </Box>
            </div>
            {showModal !== null && (
                <CustomModalMessage
                    closeModalAction={handleCancel}
                    t={t}
                    primaryButton={{
                        content: t(
                            showModal === "delete_media"
                                ? "delete_image_btn"
                                : "delete_images_btn",
                            {
                                ns: "translation",
                            }
                        ),
                        action: handleDelete,
                        tone:
                            showModal === "delete_medias"
                                ? "critical"
                                : undefined,
                    }}
                    secondaryButton={{
                        content: t("cancel", { ns: "translation" }),
                        action: handleCancel,
                    }}
                    title={t(
                        showModal === "delete_media"
                            ? "delete_image_label"
                            : "delete_images_label",
                        {
                            ns: "translation",
                        }
                    )}
                    content={t(
                        showModal === "delete_media"
                            ? "delete_image_content"
                            : "delete_images_content",
                        { ns: "translation" }
                    )}
                />
            )}
        </>
    );
};

export default MediaList;
