import { Box, Icon, InlineGrid, InlineStack, Text } from "@shopify/polaris";
import pricingList from "../../config/pricingListFunction";
import PricingItem from "./PricingItem";
import { Fragment, useState } from "react";
import planList from "../../config/pricingPlanList";
import { ChevronDownIcon } from "@shopify/polaris-icons";
const PricingTable = ({ t, collapse }) => {
    const [open, setOpen] = useState({
        summary: true,
        detail: false,
    });
    const pricingListMarkup = Object.entries(pricingList).map(
        ([key, value], index) => (
            <PricingItem
                item={value}
                key={"item-" + key}
                columns={4}
                prefix={"primary_collapse"}
                t={t}
            />
        )
    );

    const handleExplainDetail = () => {
        if (!collapse) return;
        return collapse
            ? open.detail
                ? // = (number of function) * (item height + border item bottom width) + (table header height) + (border table width top-bottom)
                  `${Object.keys(pricingList).length * (52 + 1) + 56 + 2}px`
                : "256px"
            : "fit-content";
    };

    return (
        <div
            style={{
                overflowX: "scroll",
                scrollbarWidth: "none",
            }}
        >
            <div
                style={{
                    width: "100%",
                    minWidth: "500px",
                }}
            >
                <div
                    onClick={() =>
                        setOpen({
                            ...open,
                            summary: !open.summary,
                        })
                    }
                    className="desktop-hidden"
                >
                    <Box paddingBlock={300} paddingInlineEnd={400}>
                        <InlineStack
                            blockAlign="center"
                            align="space-between"
                            wrap={false}
                        >
                            <Text variant="headingLg">
                                {t("plan_table.primary_collapse.title", {
                                    ns: "pricing",
                                })}
                            </Text>
                        </InlineStack>
                    </Box>
                </div>
                <div
                    className={`pricing-table`}
                    style={{
                        height: handleExplainDetail(),
                    }}
                >
                    <div
                        style={{
                            padding: "16px",
                        }}
                        className="pricing-table--item"
                    >
                        <InlineGrid columns={4}>
                            <Text alignment="start" variant="headingLg">
                                {t(`plan.title`, { ns: "pricing" })}
                            </Text>

                            {Object.values(planList).map((value, index) => (
                                <Fragment key={`title-${index}`}>
                                    {index !== 0 && (
                                        <div style={{ color: value.color }}>
                                            <Text
                                                alignment="center"
                                                variant="headingLg"
                                            >
                                                {t(`plan.${value.name}`, {
                                                    ns: "pricing",
                                                })}
                                            </Text>
                                        </div>
                                    )}
                                </Fragment>
                            ))}
                        </InlineGrid>
                    </div>
                    {pricingListMarkup}
                    {collapse && !open.detail && (
                        <div
                            onClick={() => {
                                setOpen({
                                    ...open,
                                    detail: true,
                                });
                            }}
                            className="view-more--detail"
                        >
                            <InlineStack
                                blockAlign="center"
                                gap={100}
                                align="center"
                            >
                                <Text>
                                    {t("view_more", { ns: "translation" })}
                                </Text>
                                <Box>
                                    <Icon source={ChevronDownIcon} />
                                </Box>
                            </InlineStack>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PricingTable;
