import {
    Box,
    InlineGrid,
    Text,
    Icon,
    InlineStack,
    Tooltip,
} from "@shopify/polaris";
import { MinusIcon, AlertCircleIcon } from "@shopify/polaris-icons";

const PricingItem = ({ item, backgroundColor, columns = 4, t, prefix }) => {
    const icon = () => {
        return (
            <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0.332108 8.40809C0.312711 3.97689 3.99159 0.406657 8.35941 0.42389C12.6166 0.440406 16.3522 3.87062 16.3321 8.44974C16.3266 10.5705 15.4784 12.6022 13.9742 14.098C12.47 15.5937 10.433 16.4308 8.31127 16.4253C6.18954 16.4198 4.15691 15.572 2.66052 14.0685C1.16414 12.565 0.326582 10.5289 0.332108 8.40809ZM7.34935 9.21807L7.25021 9.21377C7.22243 9.15345 7.18772 9.09658 7.14676 9.0443C6.75668 8.64864 6.36156 8.25443 5.97004 7.8638C5.72004 7.6096 5.414 7.51338 5.07349 7.60457C4.92398 7.64347 4.78738 7.72111 4.6775 7.82965C4.56762 7.9382 4.48834 8.07381 4.44768 8.22278C4.40701 8.37175 4.40639 8.52882 4.44589 8.67811C4.48539 8.82739 4.5636 8.96362 4.67262 9.07302C5.30768 9.71928 5.95352 10.3598 6.5936 11.0039C7.04188 11.4556 7.55193 11.4584 8.00524 11.0039L11.9205 7.09044C11.9679 7.04536 12.0125 6.9974 12.0541 6.94682C12.1419 6.83678 12.2021 6.70739 12.2298 6.5694C12.2576 6.4314 12.2519 6.28879 12.2134 6.15341C12.1749 6.01803 12.1047 5.89378 12.0085 5.79099C11.9123 5.6882 11.793 5.60983 11.6604 5.56239C11.2797 5.42811 10.9585 5.53725 10.682 5.81443C9.60486 6.89105 8.52727 7.96816 7.4492 9.04574C7.4084 9.09849 7.37482 9.15644 7.34935 9.21807Z"
                    fill="#53B735"
                />
            </svg>
        );
    };

    return (

        <div className="pricing-detail--item">
            <Box
                background={backgroundColor}
                paddingBlock={400}
                paddingInline={400}
            >
                <InlineGrid blockAlign="center" gap={100} columns={columns}>
                    {Object.keys(item).map(
                        (value, index) =>
                            typeof item[value] !== "object" &&
                            ![1, 2].includes(index) && (
                                <InlineStack
                                    key={index}
                                    blockAlign="center"
                                    align="center"
                                >
                                    {index === 0 ? (
                                        <div
                                            style={{
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "row",
                                                flexWrap: "nowrap",
                                                gap: "4px",
                                            }}
                                        >
                                            <p
                                                className="Polaris-Text--root Polaris-Text--bodyMd Polaris-Text--block Polaris-Text--start"
                                                style={{
                                                    whiteSpace: "wrap",
                                                    wordWrap: "break-word",
                                                }}
                                            >
                                                {t(
                                                    `plan_table.${prefix}.${item?.label}`,
                                                    { ns: "pricing" }
                                                )}
                                            </p>
                                            {item[value] === "email_quota" && (
                                                <Tooltip
                                                    content={t(
                                                        `plan_table.${prefix}.email_quota_helptext`,
                                                        { ns: "pricing" }
                                                    )}
                                                    zIndexOverride={1000}
                                                >
                                                    <Icon
                                                        source={AlertCircleIcon}
                                                    />
                                                </Tooltip>
                                            )}
                                        </div>
                                    ) : (
                                        <InlineStack
                                            blockAlign="center"
                                            align="center"
                                        >
                                            {typeof item[value] !==
                                            "boolean" ? (
                                                <Text alignment="center">
                                                    {typeof item[value] ===
                                                    "number"
                                                        ? `${item[value]} ${t(
                                                              `order/month`,
                                                              { ns: "pricing" }
                                                          )}`
                                                        : t(`${item[value]}`, {
                                                              ns: "pricing",
                                                          })}
                                                </Text>
                                            ) : item[value] === false ? (
                                                <Icon source={MinusIcon} />
                                            ) : (
                                                <Icon source={icon} />
                                            )}
                                        </InlineStack>
                                    )}
                                </InlineStack>
                            )
                    )}
                </InlineGrid>
            </Box>
        </div>
    );
};

export default PricingItem;
