import { useState } from "react";
const useMode = () => {
  const [csMode] = useState(() => window.__CS_TOKEN__ || false);
  const [fullscreenMode] = useState(() => window.__FULLSCREEN__ || false);
  return {
    csMode,
    fullscreenMode,
  };
};

export default useMode;
