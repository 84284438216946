// Extending the Window interface to include TS_SESSION_STORAGE

window.TS_SESSION_STORAGE = window.TS_SESSION_STORAGE || {};

const removeStorage = function (name) {
  try {
    sessionStorage.removeItem(name);
  } catch (e) {
    delete window.TS_SESSION_STORAGE[name];
  }
  return true;
};

const getStorage = function (key) {
  try {
    return sessionStorage.getItem(key) || undefined;
  } catch (e) {
    return window.TS_SESSION_STORAGE[key] || undefined;
  }
};

const setStorage = function (key, value) {
  try {
    sessionStorage.setItem(key, value);
  } catch (e) {
    window.TS_SESSION_STORAGE[key] = value;
  }
  return true;
};

export default {
  getItem: getStorage,
  setItem: setStorage,
  removeItem: removeStorage,
};
