import { useEffect } from "react";
import Routers from "./Routers";
import useShopify from "@/hook/useAppBridge";
const App = () => {
  const shopify = useShopify();
  useEffect(()=>{
    window.shopify = shopify;
  },[])
  return (
    <>
      <div className="trustshop-app--container">
        <div className="trustshop-app--wrap">
          <Routers />
        </div>
      </div>
    </>
  );
};

export default App