import {
    BlockStack,
    Text,
    Box,
    Icon,
    Collapsible,
    List,
} from "@shopify/polaris";
import { ChevronDownIcon, ChevronRightIcon } from "@shopify/polaris-icons";
import listQuestions from "../../config/listFrequencyAsk";
import { Fragment, useState } from "react";
const QuestionItem = ({ question, index, t }) => {
    const [open, setOpen] = useState(false);
    return (
        <Box
            paddingBlock={300}
            paddingInline={400}
            borderBlockEndWidth="0165"
            borderColor="border"
        >
            <div
                onClick={() => setOpen(!open)}
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                    gap: "8px",
                    flexWrap: "nowrap",
                }}
            >
                <Text>
                    {t(`frequency_ask.list_questions.${index + 1}.title`, {
                        ns: "pricing",
                    })}
                </Text>
                <Box>
                    <Icon source={open ? ChevronDownIcon : ChevronRightIcon} />
                </Box>
            </div>
            <Collapsible
                open={open}
                id="basic-collapsible"
                transition={{
                    duration: "500ms",
                    timingFunction: "ease-in-out",
                }}
                expandOnPrint
            >
                <Box paddingBlock={300}>
                    <BlockStack gap={300}>
                        {question.subtitle && (
                            <Text>
                                {t(
                                    `frequency_ask.list_questions.${
                                        index + 1
                                    }.subtitle`,
                                    { ns: "pricing" }
                                )}
                            </Text>
                        )}
                        {question.content ? (
                            typeof question.content !== "object" ? (
                                <Text>
                                    {t(
                                        `frequency_ask.list_questions.${
                                            index + 1
                                        }.content`,
                                        { ns: "pricing" }
                                    )}
                                </Text>
                            ) : question.content.length > 0 ? (
                                <List>
                                    {question.content.map((item, i) => (
                                        <Fragment
                                            key={`question-${index}-${i}`}
                                        >
                                            <List.Item>
                                                {t(
                                                    `frequency_ask.list_questions.${
                                                        index + 1
                                                    }.content.${i + 1}`,
                                                    { ns: "pricing" }
                                                )}
                                            </List.Item>
                                        </Fragment>
                                    ))}
                                </List>
                            ) : (
                                <></>
                            )
                        ) : (
                            <></>
                        )}
                    </BlockStack>
                </Box>
            </Collapsible>
        </Box>
    );
};
const FrequencyAsk = ({ t, background }) => {
    return (
        <BlockStack gap={400}>
            <Text variant="headingLg">
                {t("frequency_ask.title", { ns: "pricing" })}
            </Text>
            <Box
                borderColor="border"
                borderWidth="0165"
                borderRadius="300"
                background={background || "bg-fill-transparent"}
            >
                {listQuestions.map((question, index) => (
                    <QuestionItem
                        question={question}
                        key={`question-${index}`}
                        index={index}
                        t={t}
                    />
                ))}
            </Box>
        </BlockStack>
    );
};

export default FrequencyAsk;
