import {
    Box,
    BlockStack,
    Text,
    InlineStack,
    Tooltip,
} from "@shopify/polaris";

const CustomerInfoCard = ({ review, t }) => {
    const shortName = () => {
        const obj = review?.customer?.name.split(" ");
        return (
            (obj[0] ? obj[0].slice(0, 1) : "") +
            (obj[1] ? obj[1].slice(0, 1) : "")
        );
    };
    return (
        <div className="all-media-page__customer-info-card--container">
            <Box
                shadow="100"
                padding={400}
                background={"bg-fill"}
                borderRadius="300"
                borderWidth="0165"
                borderColor="border"
                id="all-media-page__customer-info-card--wrap"
            >
                <Box>
                    <BlockStack gap={200}>
                        <p
                            className="Polaris-Text--root"
                            style={{
                                fontSize: "16px",
                                fontWeight: "700",
                            }}
                        >
                            {t("review_detail.customer_info", {
                                ns: "review-management",
                            })}
                        </p>
                        <InlineStack
                            gap={200}
                            blockAlign="center"
                            align="space-between"
                            wrap={false}
                        >
                            <BlockStack gap={100}>
                                <InlineStack
                                    gap={150}
                                    blockAlign="center"
                                    align="start"
                                    wrap={false}
                                >
                                    <Tooltip
                                        content={review?.customer?.name}
                                        width="250px"
                                    >
                                        <Box
                                            maxWidth="200px"
                                            id="customer-name"
                                        >
                                            <Text variant="headingSm" truncate>
                                                {review?.customer?.name}
                                            </Text>
                                        </Box>
                                    </Tooltip>
                                    <svg
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M6.62402 6.94653C6.51028 6.84055 6.35985 6.78285 6.20441 6.78559C6.04896 6.78833 5.90066 6.8513 5.79073 6.96123C5.6808 7.07116 5.61783 7.21947 5.61508 7.37491C5.61234 7.53035 5.67004 7.68079 5.77602 7.79453L7.01442 9.03293C7.12692 9.14529 7.27942 9.2084 7.43842 9.2084C7.59742 9.2084 7.74992 9.14529 7.86242 9.03293L10.2328 6.66333C10.3388 6.54959 10.3965 6.39915 10.3938 6.24371C10.391 6.08827 10.3281 5.93996 10.2181 5.83003C10.1082 5.7201 9.95988 5.65713 9.80444 5.65439C9.649 5.65165 9.49856 5.70935 9.38482 5.81533L7.43842 7.76013L6.62402 6.94653Z"
                                            fill="#4A4A4A"
                                        />
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M8.87443 2.33106C8.62622 2.13256 8.31785 2.02441 8.00003 2.02441C7.6822 2.02441 7.37384 2.13256 7.12563 2.33106L6.87203 2.53346C6.08295 3.16496 5.21846 3.69596 4.29843 4.11426L3.62083 4.42146C3.37151 4.53307 3.16131 4.71676 3.01731 4.94888C2.8733 5.181 2.80208 5.45091 2.81283 5.72386C3.03683 10.7239 6.31683 12.9623 7.39843 13.5655C7.77443 13.7751 8.22563 13.7751 8.60083 13.5655C9.68243 12.9631 12.964 10.7255 13.188 5.73906C13.1985 5.46749 13.1278 5.19896 12.9851 4.96771C12.8423 4.73646 12.6339 4.55298 12.3864 4.44066L11.6664 4.10786C10.7705 3.69442 9.92812 3.17382 9.15763 2.55746L8.87443 2.33106ZM7.87443 3.26786C7.90993 3.23936 7.9541 3.22382 7.99963 3.22382C8.04516 3.22382 8.08932 3.23936 8.12483 3.26786L8.40803 3.49426C9.25442 4.17137 10.1799 4.74329 11.164 5.19746L11.884 5.52946C11.9149 5.54232 11.9412 5.56397 11.9599 5.59171C11.9785 5.61944 11.9886 5.65205 11.9888 5.68546C11.792 10.0767 8.92723 12.0103 8.01683 12.5175C8.01198 12.5204 8.00648 12.522 8.00083 12.5223C7.99683 12.5223 7.99123 12.5223 7.98323 12.5175C7.07443 12.0103 4.20883 10.0751 4.01123 5.66946C4.00883 5.61666 4.04083 5.54946 4.11763 5.51426L4.79443 5.20626C5.8051 4.74694 6.75478 4.1638 7.62163 3.47026L7.87443 3.26786Z"
                                            fill="#4A4A4A"
                                        />
                                    </svg>
                                </InlineStack>
                                <Tooltip
                                    content={review?.customer?.email}
                                    width="250px"
                                    preferredPosition="below"
                                >
                                    <Box maxWidth="200px" id="customer-email">
                                        <Text truncate>
                                            {review?.customer?.email}
                                        </Text>
                                    </Box>
                                </Tooltip>
                            </BlockStack>
                            <div
                                className="all-media-page__customer-info--avt"
                            >
                                <Text>{shortName()}</Text>
                            </div>
                        </InlineStack>
                        <BlockStack gap={0}>
                            <Text fontWeight="bold">
                                {t("review_detail.country", {
                                    ns: "review-management",
                                })}
                            </Text>
                            <Text>
                                {review.country_name ||
                                    review?.customer?.country ||
                                    review?.country_code}
                            </Text>
                        </BlockStack>
                        <BlockStack gap={0}>
                            <Text fontWeight="bold">IP</Text>
                            <Text>
                                {review?.customer?.last_ip ||
                                    review?.location?.ip}
                            </Text>
                        </BlockStack>
                    </BlockStack>
                </Box>
            </Box>
        </div>
    );
};

export default CustomerInfoCard;
