import { Modal as AppBridgeModal, SaveBar, TitleBar } from "@shopify/app-bridge-react";
import { FullscreenBar } from "@shopify/polaris";
import useMode from "@/hook/useMode";
import { lazy, Suspense } from "react";
const CustomPage = lazy(() => import("@/pages/SettingWidget/PopularWidget/CustomizePage"));
const VoucherCustomizePage = lazy(() => import("@/pages/DiscountVoucher/CustomizePage"));
const ModalComponent = props => {
  console.log("ModalComponent ", props);
  const {
    open = false,
    module = "",
    onHide = () => {},
    showSaveBar = false,
    src = "",
  } = props || {};
  const { csMode } = useMode();
  const handleSendMessageToModal = actions => {
    document
      .getElementById("modal-fullscreen")
      .contentWindow.postMessage(actions, window.location.origin);
  };
  const handleSave = () => {
    handleSendMessageToModal("save");
  };
  const handleDiscard = () => {
    handleSendMessageToModal("discard");
  };
  if (csMode) {
    return (
      <>
        {props?.open && (
          <Suspense fallback={<></>}>
            <div className="app-cs-fullscreen">
              <div className="app-cs-fullscreen-header">
                <FullscreenBar
                  onAction={() => {
                    props?.onHide();
                  }}
                />
              </div>
              <div className="app-cs-fullscreen-body">
                {module === "SettingWidget" && <CustomPage section={props?.section} />}
                {module === "DiscountVoucher" && <VoucherCustomizePage section={props?.section} />}
              </div>
            </div>
          </Suspense>
        )}
      </>
    );
  }
  return (
    <AppBridgeModal src={src} id={"modal-fullscreen"} open={open} variant={"max"} onHide={onHide}>
      <SaveBar open={showSaveBar}>
        <button variant={"primary"} onClick={handleSave}></button>
        <button onClick={handleDiscard}></button>
      </SaveBar>
      <TitleBar title="Trustshop - Product Reviews" />
    </AppBridgeModal>
  );
};

export default ModalComponent;
