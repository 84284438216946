import { Modal, TitleBar } from "@/components/AppBridge";
import {
    Box,
    InlineStack,
    Text,
    Button,
    Icon,
    BlockStack,
    Card,
    List,
    ButtonGroup,
} from "@shopify/polaris";
import { AlertTriangleIcon, XIcon } from "@shopify/polaris-icons";
import PricingCard from "./PricingCard";
import PricingTable from "./PricingTable";
import "../../assets/scss/pricing.scss";
import planList from "../../config/pricingPlanList";
import { useTranslation } from "react-i18next";
import { Fragment, useState } from "react";
import Http from "../../layouts/Http";
import * as Config from "../../api/Config";
import { useDispatch } from "react-redux";
import * as ActionTypes from "../../store/action-types";
import BannerFreePlan from "./BannerFreePlan";
import FrequencyAsk from "./FrequencyAsk";
import listFunction from "../../config/pricingListFunction";
import { planActiveTracking } from "../../utils/planActiveTracking";
const PricingPage = ({
    shopInfo,
    isModal,
    setShowPricing,
    isDashboard,
    page,
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation(["translation", "pricing"]);
    const [isCharging, setIsCharging] = useState(false);
    const [isDowngrade, setIsDowngrade] = useState(false);
    const [selectPlan, setSelectPlan] = useState("");
    const handleChangePlan = () => {
        planActiveTracking(
            "Setting",
            t(`plan.${selectPlan}`, { ns: "pricing" })
        );
        if (isDowngrade) {
            setIsDowngrade(false);
        }
        Http.post(`${Config.API_URL}/charge/generate-url`, {
            plan: selectPlan,
        })
            .then((res) => {
                if (res.data.status) {
                    if (
                        res.data.data.confirmation_url &&
                        res.data.data.plan !== "free"
                    ) {
                        window.open(res.data.data.confirmation_url, "_top");
                    } else {
                        window.shopify.toast.show(
                            t(`plan_active`, {
                                ns: "pricing",
                                plan: t(`plan.free_plan`, {
                                    ns: "pricing",
                                }),
                                duration: 3000,
                            })
                        );
                        dispatch({
                            type: ActionTypes.UPDATE_SHOP_INFO,
                            payload: res.data.data,
                        });
                    }
                } else {
                    window.shopify.toast.show(t("toast.server_error"), {
                        ns: "message",
                    });
                }
                setSelectPlan("");
            })
            .catch((e) => {
                window.shopify.toast.show(t("toast.server_error"), {
                    ns: "message",
                });
                console.log(e);
            });
    };

    return (
        <>
            <div
                style={{
                    borderRadius: "12px",
                    overflow: "hidden",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: "950px",
                }}
                className="pricing-page"
            >
                {isModal && (
                    <div
                        style={{
                            padding: "12px",
                            backgroundColor: "#F3F3F3",
                            borderBottom: "1px solid #E3E3E3",
                            width: "100%",
                            borderRadius: "12px 12px 0 0",
                        }}
                    >
                        <InlineStack
                            gap={200}
                            wrap={false}
                            align="space-between"
                        >
                            <Text variant="headingMd">
                                {t("modal_pricing_title", { ns: "pricing" })}
                            </Text>
                            <Button
                                variant="monochromePlain"
                                onClick={() => {
                                    if (isModal) {
                                        setShowPricing(false);
                                    }
                                    if (!isDashboard) return;
                                    Http.post(
                                        `${Config.API_URL}/charge/close-modal`
                                    )
                                        .then((data) => {
                                            if (data.data?.status) {
                                                dispatch({
                                                    type: ActionTypes.UPDATE_SHOP_INFO,
                                                    payload: {
                                                        show_pricing: false,
                                                    },
                                                });
                                            }
                                        })
                                        .catch((e) => {
                                            throw e;
                                        });
                                }}
                            >
                                <Icon source={XIcon} />
                            </Button>
                        </InlineStack>
                    </div>
                )}
                <div
                    style={{
                        overflow: "auto",
                        scrollbarWidth: "none",
                        borderRadius: "0px 0px 12px 12px",
                        backgroundColor: "white",
                    }}
                >
                    <div
                        style={{
                            height: "fit-content",
                        }}
                    >
                        <Box padding={600}>
                            <BlockStack gap={400}>
                                <BlockStack gap={400}>
                                    <div className="pricing-plan--container">
                                        <div
                                            style={{
                                                minWidth: "215px",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                width: "100%",
                                                gap: "8px",
                                                flex: 1,
                                            }}
                                            className="pricing-foreword"
                                        >
                                            <Text variant="heading3xl">
                                                {t("plan_table.title", {
                                                    ns: "pricing",
                                                })}
                                            </Text>
                                            <Text alignment="start">
                                                {t("plan_table.subtitle", {
                                                    ns: "pricing",
                                                })}
                                            </Text>
                                        </div>
                                        <div
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                            }}
                                        >
                                            <InlineStack
                                                gap={300}
                                                wrap={false}
                                                blockAlign="stretch"
                                            >
                                                {Object.entries(planList).map(
                                                    ([key, value], index) => (
                                                        <Fragment key={key}>
                                                            {index !== 0 && (
                                                                <PricingCard
                                                                    plan={value}
                                                                    variant={
                                                                        Number(
                                                                            Object.keys(
                                                                                planList
                                                                            ).findIndex(
                                                                                (
                                                                                    item
                                                                                ) =>
                                                                                    planList[
                                                                                        item
                                                                                    ]
                                                                                        .name ===
                                                                                    shopInfo.current_plan
                                                                            )
                                                                        ) <=
                                                                        index
                                                                            ? "primary"
                                                                            : "secondary"
                                                                    }
                                                                    disable={
                                                                        shopInfo.current_plan ===
                                                                            value.name ||
                                                                        isCharging
                                                                    }
                                                                    shopInfo={
                                                                        shopInfo
                                                                    }
                                                                    t={t}
                                                                    isCharging={
                                                                        isCharging
                                                                    }
                                                                    setIsCharging={
                                                                        setIsCharging
                                                                    }
                                                                    discount={
                                                                        null
                                                                    }
                                                                    bestChoice={
                                                                        value.name ===
                                                                        "pro"
                                                                    }
                                                                    contactUs={
                                                                        false
                                                                    }
                                                                    isDowngrade={
                                                                        isDowngrade
                                                                    }
                                                                    setIsDowngrade={
                                                                        setIsDowngrade
                                                                    }
                                                                    setSelectPlan={
                                                                        setSelectPlan
                                                                    }
                                                                    page={page}
                                                                    planActiveTracking={
                                                                        planActiveTracking
                                                                    }
                                                                />
                                                            )}
                                                        </Fragment>
                                                    )
                                                )}
                                            </InlineStack>
                                        </div>
                                    </div>
                                    <PricingTable t={t} />
                                </BlockStack>
                                <BannerFreePlan
                                    t={t}
                                    current_plan={shopInfo?.current_plan}
                                    handleChangePlan={handleChangePlan}
                                    setIsDowngrade={setIsDowngrade}
                                    setSelectPlan={setSelectPlan}
                                    page={page}
                                />
                                <FrequencyAsk t={t} />
                            </BlockStack>
                        </Box>
                    </div>
                </div>
            </div>

            <Modal
                size="large"
                id="modal-downgrade"
                open={isDowngrade}
                onHide={() => setIsDowngrade(false)}
            >
                <Box padding={400}>
                    <BlockStack gap={500}>
                        <Box>
                            <Text fontWeight="bold">
                                {t(
                                    "plan_table.modal_downgrade.downgrade_reminder",
                                    {
                                        ns: "pricing",
                                    }
                                )}
                            </Text>
                            <Text>
                                {t(
                                    "plan_table.modal_downgrade.downgrade_reminder_subtitle",
                                    {
                                        ns: "pricing",
                                    }
                                )}
                            </Text>
                        </Box>
                        <List type="bullet">
                            {Object.entries(listFunction).map(
                                ([key, value], index) => (
                                    <Fragment key={index + key}>
                                        {value.unlimited !== "coming_soon" && (
                                            <>
                                                {key === "email_quota" ? (
                                                    <List.Item>
                                                        {value[
                                                            shopInfo
                                                                ?.current_plan
                                                        ] +
                                                            " " +
                                                            t("order/month", {
                                                                ns: "pricing",
                                                            })}
                                                    </List.Item>
                                                ) : (
                                                    value["free"] === false && (
                                                        <List.Item>
                                                            {t(
                                                                `plan_table.modal_downgrade.${key}`,
                                                                {
                                                                    ns: "pricing",
                                                                }
                                                            )}
                                                        </List.Item>
                                                    )
                                                )}
                                            </>
                                        )}
                                    </Fragment>
                                )
                            )}
                        </List>
                    </BlockStack>
                </Box>
                <TitleBar
                    title={t("plan_table.modal_downgrade.title", {
                        ns: "pricing",
                    })}
                >
                    <button
                        variant={"primary"}
                        onClick={() => setIsDowngrade(false)}
                    >
                        {t("plan_table.modal_downgrade.keep_current_plan", {
                            ns: "pricing",
                        })}
                    </button>
                    <button
                        onClick={() => {
                            handleChangePlan();
                        }}
                    >
                        {t("plan_table.modal_downgrade.confirm_downgrade", {
                            ns: "pricing",
                        })}
                    </button>
                </TitleBar>
            </Modal>
        </>
    );
};

export default PricingPage;
