export function isFreePlan(shop) {
	if (shop?.priority) return false
	if (!shop?.current_plan) return true;
	return shop?.current_plan === 'free';
}

export function isShowMedia(shop) {
	return true;
	if (!shop) return false;

	return !isFreePlan(shop);
}
