const listFunction = {
    email_quota: {
        label: "email_quota",
        null: 50,
        free: 50,
        basic: 200,
        pro: 1500,
        unlimited: "unlimited",
    },

    discount_voucher: {
        label: "discount_voucher",
        null: false,
        free: false,
        basic: true,
        pro: true,
        unlimited: true,
    },

    customize_widget: {
        label: "customize_widget",
        null: "basic",
        free: "basic",
        basic: "fully",
        pro: "fully",
        unlimited: "fully",
        detail: {
            widgetBox: {
                label: "Widget box (default)",
                choose_layout: {
                    label: "choose_layout",
                    null: false,
                    free: false,
                    basic: true,
                    pro: true,
                    unlimited: true,
                },
                summary_box: {
                    label: "summary_box",
                    null: false,
                    free: false,
                    basic: true,
                    pro: true,
                    unlimited: true,
                },
                color_scheme: {
                    label: "color_scheme",
                    null: true,
                    free: true,
                    basic: true,
                    pro: true,
                    unlimited: true,
                },
                information_display: {
                    label: "information_display",
                    null: true,
                    free: true,
                    basic: true,
                    pro: true,
                    unlimited: true,
                },
                content: {
                    label: "content",
                    null: true,
                    free: true,
                    basic: true,
                    pro: true,
                    unlimited: true,
                },
            },
            widgetBoxNoReview: {
                label: "Widget box (no reviews)",
                content: {
                    label: "content",
                    null: true,
                    free: true,
                    basic: true,
                    pro: true,
                    unlimited: true,
                },
                color_scheme: {
                    label: "color_scheme",
                    null: true,
                    free: true,
                    basic: true,
                    pro: true,
                    unlimited: true,
                },
            },
            modalReviewDetail: {
                label: "Modal Review Detail",
                all_settings: {
                    label: "all_settings",
                    null: false,
                    free: false,
                    basic: true,
                    pro: true,
                    unlimited: true,
                },
            },
            modal_all_image: {
                label: "modal_all_image",
                all_settings: {
                    label: "all_settings",
                    null: false,
                    free: false,
                    basic: true,
                    pro: true,
                    unlimited: true,
                },
            },
            formWriteReview: {
                label: "Form Write Review",
                review_with_video: {
                    label: "review_with_video",
                    null: false,
                    free: false,
                    basic: true,
                    pro: true,
                    unlimited: true,
                },
            },
            popupAfterReview: {
                label: "Popup after Review",
                pending: {
                    label: "Popup after Review - Pending",
                    null: false,
                    free: false,
                    basic: true,
                    pro: true,
                    unlimited: true,
                },
                success: {
                    label: "Popup after Review - Success",
                    null: false,
                    free: false,
                    basic: true,
                    pro: true,
                    unlimited: true,
                },
            },
        },
    },

    upload_video: {
        label: "upload_video",
        null: false,
        free: false,
        basic: true,
        pro: true,
        unlimited: true,
    },

    review_testimonial: {
        label: "review_testimonial",
        null: false,
        free: false,
        basic: true,
        pro: true,
        unlimited: true,
    },

    review_page: {
        label: "review_page",
        null: false,
        free: false,
        basic: true,
        pro: true,
        unlimited: true,
    },

    google_shopping: {
        label: "google_shopping",
        null: false,
        free: false,
        basic: true,
        pro: true,
        unlimited: true,
    },

    global_display: {
        label: "global_display",
        null: false,
        free: false,
        basic: true,
        pro: true,
        unlimited: true,
    },

    group_product: {
        label: "group_product",
        null: false,
        free: false,
        basic: true,
        pro: true,
        unlimited: true,
    },

    import_custom_csv: {
        label: "import_custom_csv",
        null: true,
        free: true,
        basic: true,
        pro: true,
        unlimited: true,
    },

    protect_review: {
        label: "protect_review",
        null: false,
        free: false,
        basic: true,
        pro: true,
        unlimited: true,
    },
    
    review_detail_page: {
        label: "review_detail_page",
        null: false,
        free: false,
        basic: false,
        pro: true,
        unlimited: true,
    },

    custom_domain: {
        label: "custom_domain",
        null: false,
        free: false,
        basic: false,
        pro: true,
        unlimited: true,
    },

    qr_code: {
        label: "qr_code",
        null: false,
        free: false,
        basic: false,
        pro: true,
        unlimited: true,
    },

    review_translation: {
        label: "review_translation",
        null: false,
        free: false,
        basic: false,
        pro: true,
        unlimited: true,
    },

    multi_lang: {
        label: "multi_lang",
        null: false,
        free: false,
        basic: false,
        pro: true,
        unlimited: true,
    },

    ai_functionality: {
        label: "ai_functionality",
        null: false,
        free: false,
        basic: false,
        pro: "coming_soon",
        unlimited: "coming_soon",
    },

    insights_and_segmentation: {
        label: "insights_and_segmentation",
        null: false,
        free: false,
        basic: false,
        pro: "coming_soon",
        unlimited: "coming_soon",
    },
};

export default listFunction;
